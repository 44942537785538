export const experienceData = [
    {
        id: 1,
        company: 'Infosys',
        jobtitle: 'Senior Software Engineer',
        startYear: 'Nov 2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Aspire Systems Pvt Ltd',
        jobtitle: 'Software Engineer',
        startYear: 'Jun 2019',
        endYear: 'Oct 2022'
    }
]
import mail from '../assets/svg/projects/mail.svg'
import trackEasy from '../assets/svg/projects/seven.svg'
import ecommerce from '../assets/svg/projects/ecommerce-website-icon.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'TrackEasy',
        projectDesc: 'A CRM application has been developed to streamline the management and tracking of user records for enhanced convenience.',
        tags: ['React', 'Python-Django', 'Antd Design'],
        code: '',
        demo: '',
        image: trackEasy
    },
    {
        id: 2,
        projectName: 'EasyShop',
        projectDesc: 'Created an e-commerce website to upload and sell the products online.',
        tags: ['React & Redux', 'Express', 'TailWind', 'Firebase'],
        code: 'https://github.com/ArjunTheDev/EMCKart',
        demo: '',
        image: ecommerce
    },
    {
        id: 3,
        projectName: 'Bulk Mailer',
        projectDesc: 'Send an email to the list of users in the Excel file with a custom template.',
        tags: ['React', 'CSS', 'Express'],
        code: 'https://github.com/ArjunTheDev/EMC-Mailer',
        demo: '',
        image: mail
    }
]

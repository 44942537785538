export const skillsData = [
    'HTML',
    'Javascript',
    'CSS',
    'React',
    'NodeJs',
    'Vue',
    'Typescript',
    'Ubuntu',
    'MongoDB',
    'Tailwind',
    'MySQL',
    'PostgreSQL',
    'Firebase',
    'AWS',
    'Microsoft Office'
]

export const educationData = [
    {
        id: 1,
        institution: 'Sri Eshwar College of Engineering',
        course: 'Bachelor Degree',
        startYear: '2015',
        endYear: '2019'
    },
    {
        id: 2,
        institution: 'Vidhya Vikas Boys Higher Sec School, Namakkal',
        course: 'Higher Secondary',
        startYear: '2013',
        endYear: '2015'
    },
    {
        id: 3,
        institution: 'Sri Vidhya Mandir Matriculation School, Namakkal',
        course: 'Secondary School',
        startYear: '2003',
        endYear: '2013'
    },
]